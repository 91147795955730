/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Color palette */

.wn-black {
    color: #21272A;
}
/* Header Text, Bold Text */
.wn-dark {
    color: #394854;
}
/* Alternate Header Text */
.wn-blue {
    color: #5586A6;
}
/* Page background */
.wn-white {
    color: #FEFEFE;
}
/* Cards background */
.wn-light-grey {
    color: #F0F0F0;
}
/* Borders */
.wn-grey {
    color: #CACBCF;
}
/* Text */
.wn-dark-grey {
    color: #888888;
}
/* Buttons */
.wn-orange {
    color: #F89938;
}
.wn-red {
    color: #FF3333;
}
.wn-black-background {
    background-color: #21272A;
}
/* Header Text, Bold Text */
.wn-dark-background {
    background-color: #394854;
}
/* Alternate Header Text */
.wn-blue-background {
    background-color: #5586A6;
}
/* Page background */
.wn-white-background{
    background-color: #FEFEFE;
}
/* Cards background */
.wn-light-grey-background {
    background-color: #F0F0F0;
}
/* Borders */
.wn-grey-background {
    background-color: #CACBCF;
}
/* Text */
.wn-dark-grey-background {
    background-color: #888888;
}
/* Buttons */
.wn-orange-background {
    background-color: #F89938;
}
.wn-red-background {
    background-color: #FF3333;
}

/* Deprecated color palette */

.palette-color-black {
    color: black;
}
.palette-background-color-black {
    background-color: black;
}
.palette-color-less-black {
    color: #21272A;
}
.palette-background-color-less-black {
    background-color: #21272A;
}
.palette-color-white {
    color: #FEFEFE;
}
.palette-background-color-white {
    background-color: #FEFEFE;
}
.palette-color-grey {
    color: #394854;
}
.palette-background-color-grey {
    background-color: #394854;
}
.palette-color-light-grey {
    color: #efefef;
}
.palette-background-color-light-grey {
    background-color: #efefef;
}
.palette-color-yellow {
    color: #FFC931;
}
.palette-background-color-yellow {
    background-color: #FFC931;
}
.palette-color-green {
    color: #3ECE4C;
}
.palette-background-color-green {
    background-color: #3ECE4C;
}
.palette-color-red {
    color: #FF3333;
}
.palette-background-color-red {
    background-color: #FF3333;
}
.palette-color-stronger-red {
    color: #F90000;
}
.palette-background-color-stronger-red {
    background-color: #F90000;
}
.palette-color-strongest-red {
    color: #E01919;
}
.palette-background-color-strongest-red {
    background-color: #E01919;
}

.primary-color {
    color: #3F51B5;
}
.secondary-color {
    color: #E91E63;
}
.highlight-color {
    color: #F44336;
}
.border-color {
    color: #BDBDBD;
}
.error-color {
    color: #FF3333;
}
.success-color {
    color: #3ECE4C;
}

.spacer {
    flex: 1 1 auto;
}
/* centered-content-container, centered-content-header, centered-content and centered-content-footer is to center the entire page content both horizontally and vertically */
.centered-content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
}
.centered-content-header {
    flex: 0 1 auto;
}
.centered-content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.centered-content-footer {
    flex: 0 1 auto;
}
.form {
    width: 400px;
}
.form-mobile {
    width: 100%;
}
.form-field {
    width: 100%;
}
tr.data-table-expanded-row {
}
tr.data-table-row:not(.data-table-expanded-row):hover {
    background: whitesmoke;
}
.container-with-overlay {
  position: relative;
  display: inline-block;
}
.container-overlay-top-right-12px-outside {
  position: absolute;
  right: -24px;
  top: -24px;
  z-index: 5;
}
.link-like:hover {
    cursor: pointer;
}
.no-spacing-table {
    border-spacing: 0;
}
.no-padding-table th, td {
    padding: 0;
}
.no-spacing-no-padding-table {
    border-spacing: 0;
} th, td {
    padding: 0;
}
.toolbar-table {
    border-spacing: 0;
} th, td {
    padding: 0;
}
